import * as React from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { FiCopy } from 'react-icons/fi';
import {  FaWhatsapp } from 'react-icons/fa';
import CardController from "../SummaryCard/SummaryCardController";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const CopySummaryText = () => {
  const [state, setState] = React.useState({
    open: false,
    Transition: Grow,
  });

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const { filterOnlyInvetoryCardData } = CardController();

  const generateText = async () => {
    var resultCompanies = await filterOnlyInvetoryCardData();
    let textCopy = "";
    resultCompanies.shippingCompanies.forEach((line) => {
      line.containers.forEach((container) => {
        if (container.Quantity > 0 && container.Size !== "UNK") {
          textCopy += `${container.Quantity} x ${container.Size} - ${line.ssl.scacCode} \n`;
        }
      });
    });
    return textCopy;
  };

  const copySummary = async () => {
    const textCopy = await generateText();
    try {
      await navigator.clipboard.writeText(textCopy);
      setState({ open: true, Transition: GrowTransition });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const sendWhatsAppMessage = async () => {
    const dataSummary = await generateText();
    const message = `Hi, here is the summary *Empties Supra Avalables*: \n${dataSummary} *You can see ours empties availables in realtime:* https://stlinessummary.dray-power.com/`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<FiCopy />}
        onClick={copySummary}
        style={buttonStyle}
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<FaWhatsapp />}
        onClick={sendWhatsAppMessage}
        style={buttonStyle}
      />

      <Snackbar
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        message="Summary copied to clipboard"
        key={state.Transition.name}
        autoHideDuration={1200}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#4CAF50',
          }
        }}
      />
    </>
  );
};

const buttonStyle = {
  marginTop: "1%",
  marginBottom: "1%",
  backgroundColor: "transparent",
  fontSize: "14px",
  minWidth: "15%",
  fontWeight: "600",
  border: "none",
  marginRight: "8px",
  height: "40px",
  boxShadow: "none"
};

export default CopySummaryText;

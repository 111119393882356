import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./card.css";
import CardController from "./CardController";
import { p_bg_card, togglePbgCard } from "../../Store/cardConst";

const Card = ({ cardInfo }) => {
  const { containers, inventory } = cardInfo;

  // Ordenar los contenedores por su tamaño
  // const sortedContainers = [...containers].sort((a, b) =>
  //   a.Size.localeCompare(b.Size)
  // );

  // Llenar con elementos vacíos si hay menos de 4 tipos de container
  const filledContainers = [
    ...containers,
    ...Array(Math.max(0, 4 - containers.length)).fill(null),
  ];

  const renderData = filledContainers.map((container, index) => (
    <React.Fragment key={index}>
      {/* {console.log("p_bg_card", p_bg_card)} */}
      <p
        className={p_bg_card ? "nombre" : "nombre bg-colored"}
        id={container.Quantity > 0 ? "pBold" : null}
      >
        {container
          ? `${container.Size} ` +
            `${container.Size === "" ? " " : "x"}` +
            ` ${container.Quantity}`
          : "x0"}
      </p>
      {togglePbgCard()}
    </React.Fragment>
  ));

  const buttonClass = `btn ${
    inventory ? "btn-primary" : "btn-secondary opacity-50 disabled"
  }`;
  const buttonText = inventory ? "Select" : "No Inventory";

  const logoUrl = `${process.env.REACT_APP_ICONLINES_URL}${cardInfo.ssl.scacCode}.svg`;
  const defaultLogoUrl = `${process.env.REACT_APP_ICONLINES_URL}default.png`;

  return (
    <div className="linecard">
      <div className="cardContainer">
        <img
          src={cardInfo.ssl.scacCode === "default" ? defaultLogoUrl : logoUrl}
          onError={(e) => {
            e.target.src = defaultLogoUrl;
          }}
          style={{ maxWidth: "90%" }}
          alt="Line Logo"
        ></img>
        <hr className="linea" />
        <div className="stINFO">{renderData}</div>
        <a
          className={buttonClass}
          href={`/yms-st/streetturn-sslsummarydetails/?sslScac=${cardInfo.ssl.scacCode}`}
          style={{
            borderRadius: "30px",
            maxHeight: "15%",
            minWidth: "50%",
            maxWidth: "100%",
            justifyContent: "center",
            textAlign: "center",
            display: "inline-flex",
            alignItems: "center",
            marginTop: "2%",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
            fontSize: "70%",
            marginBottom: "5%",
          }}
          // onClick={() => CardController.handleClickCard(cardInfo)}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

export default Card;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const ButtonEmpties = () => {
  return (
    <>
      <a
        className="btn btn-primary btn-lg"
        href={`/yms-st/streetturn-sslsummarydetails/?sslScac=ALL`}
        style={{
          marginTop: "1%",
          marginBottom: "1%",
          backgroundColor: "#FC4A1C",
          fontSize: "14px",
          minWidth: "15%",
          fontWeight: "600",
          border: "none",
          height: "40px",
        }}
      >
        EMPTIES FOR ST
      </a>
    </>
  );
};
export default ButtonEmpties;

import axios from "axios";
import linelogo from "../assets/linelogo.png";
import mockup_null from "../services/mockup_null.json";
import current_lines from "../services/current_lines.json";

export const getCardsLines = async () => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  return axios.get(process.env.REACT_APP_GET_ALL_EMPTIES, { headers: headers });
};

export const getNullCards = async () => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const data = mockup_null;
    return { data };
  } catch (error) {
    throw error;
  }
};

export const getCurrentLines = async () => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const data = current_lines;
    return { data };
  } catch (error) {
    throw error;
  }
};

export const getLineLogo = async () => {
  try {
    return { linelogo: linelogo };
  } catch (error) {
    throw error;
  }
};

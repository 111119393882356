import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./summaryCard.css";
import CopySummaryText from "../CopySummaryText/CopySummaryText";
import Card from "../Cards/Cards";
import CardController from "./SummaryCardController";

const SummaryCard = () => {
  const [cardsInfo, setCardsInfo] = useState([]);
  const [cardInfoOriginal, setCardsInfoOriginal] = useState([]);
  const [show, setShow] = useState(true);
  const [filtered, setFiltered] = useState(false);
  const {fetchCardData} = CardController();
  useEffect(() => {
    async function fetchData() {
      try {
        const responseCardData = await fetchCardData();
        const cardInfoArray = responseCardData.shippingCompanies;
        setCardsInfoOriginal(cardInfoArray);
        setCardsInfo(cardInfoArray);
        setShow(false);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setShow(false);
      }
    }

    fetchData();
  }, []);

  async function filteredCards() {
    try {
      var responseCardData = null;
      if(filtered == false)
      {
        
        setCardsInfo(cardsInfo.filter(company => company.inventory === true && company.ssl.scacCode != null));
        setFiltered(true);
      }else
      {
        setCardsInfo(cardInfoOriginal);
        setFiltered(false);
      }
      
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }

  }

  return (
    <div className="container-fluid" id="SummarySSL" style={{ maxHeight: "80%", minHeight: "80%" }}>
      <div className="row" style={{ justifyContent: "center" }}>
        <h1 className="text-center ssl-title">
          <div class="row" >
            <div class="col-sm" style={{ display: "flex", alignItems: "center" }}>
              <input
                className="react-switch-checkbox"
                id="react-switch-new"
                type="checkbox"
                onClick={filteredCards}
              />
              <label
                style={{ background: filtered && '#06D6A0' }}
                className="react-switch-label"
                htmlFor="react-switch-new"
              >
                <span className="react-switch-button" />
              </label>
              <p style={{ fontSize: "40%", margin: "0 0 0 8px" }}>Hide No Inventory</p>
            </div>
            <div class="col-sm">
              SSL Summary
            </div>
            <div class="col-sm"  style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CopySummaryText /> 
            </div>
          </div>
        </h1>
      </div>

      <div className="row row-ssl" style={{
        justifyContent: "center",
        boxShadow:
          "0 8px 11px -5px rgba(0,0,0,.2),0 17px 26px 2px rgba(0,0,0,.14),0 6px 32px 5px rgba(0,0,0,.12)",
        overflowY: "scroll",
        maxHeight: "100%",
        minHeight: "100%",
      }}>

        {show ? (
          <div className="d-flex justify-content-center" style={{ minHeight: '70vh' }}>
            <div className="spinner-border" role="status" style={{ alignSelf: 'center', scale: '5', color: '#22224f' }}></div>
          </div>
        ) : (
          cardsInfo.map((cardInfo, index) => (
            <div key={index} className="col-md-4 col-lg-3 col-xl-2 col-sm-6 col-xs-12" style={{ padding: "2%" }}>
              <Card cardInfo={cardInfo} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SummaryCard;

import React from "react";
import "./App.css";
import SummaryCard from "./components/SummaryCard/SummaryCard";
import ButtonEmpties from "./components/Button/Button";
import PrintSummary from "./components/PrintSummary/PrintSummary";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Root(props) {
  return (
    <>
      <div className="App">
        <div
          className="container-fluid"
          style={{ height: "100%", width: "90%", paddingTop: "1%" }}
        >
          <div style={{ justifyContent: "end", display: "flex" }}>
            <PrintSummary />
            <ButtonEmpties />
          </div>

          <SummaryCard />
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";

// Variable booleana inicial
let p_bg_card = true;

// Función para cambiar el valor de la variable
export function togglePbgCard() {
  p_bg_card = !p_bg_card;
}

// Exporta la variable
export { p_bg_card };

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PrintSummary.css";

const PrintSummary = () => {
  const printDocument = () => {
    const divToPrint = document.getElementById("SummarySSL");
    const htmlToPrint = divToPrint.outerHTML; // Obtiene el HTML completo del div, incluyendo el contenedor

    const printWindow = window.open("", "_blank", "height=1000,width=1000");

    // Recoge los estilos del documento original
    const styles = Array.from(
      document.querySelectorAll("style, link[rel='stylesheet']")
    );
    const styleHtml = styles.map((style) => style.outerHTML).join("\n");

    printWindow.document.write(`
    <!DOCTYPE html>
    <html>
    
    <head>
      <title>Imprimir Div</title>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      ${styleHtml} 
    </head>
    <body>
      ${htmlToPrint} 
    </body>
    </html>
  `);
    console.log(printWindow.document);
    printWindow.document.close();

    printWindow.onload = function () {
      printWindow.focus(); // Necesario para algunos navegadores
      printWindow.print();
      printWindow.close();
    };
  };
  return (
    <>
        <a
          className="btn btn-primary btn-lg"
          onClick={printDocument}
          style={{
            marginTop: "1%",
            marginBottom: "1%",
            backgroundColor: "#FC4A1C",
            fontSize: "14px",
            minWidth: "15%",
            fontWeight: "600",
            border: "none",
            marginRight: "8px",
            height: "40px",
          }}
        >
          PRINT SUMMARY
        </a>
    </>
  );
};
export default PrintSummary;
